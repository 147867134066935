import React from "react";
import { useMemo, useRef } from "react";
import L from "leaflet";
import { useBookingsMapContext } from "../Context/BookingsMapContext";
import {
  FloorChildrenType,
  useMarkerData,
} from "../../../Floors/lib/floorData";
import { floorPlanHelpers } from "../../../Floors/lib/floorPlanHelpers";
import { renderToStaticMarkup } from "react-dom/server";

import { UserLeafletMarker } from "../../../shared/UserLeafletMarker/UserLeafletMarker";
import { BusyTimeSlots } from "../../../../api/grpc/workplaces/ggevent/ggevent";
import { MarkerSettings } from "../../../Floors/lib/useMarkerData";
import { AmenitiesFilter } from "../../../../api/grpc/workplaces/workplaces";

interface MarkerType {
  id: string;
  marker: L.Marker;
  isBooked: boolean;
  isAssigned: boolean;
  isBlocked: boolean;
  busySlot: BusyTimeSlots[];
  type: string;
  location: string;
  name: string;
  tags: string[];
  username: string;
  attachedUserPopup?: boolean;
  attendeeImage: string;
  avatar?: string;
  amenities?: AmenitiesFilter;
  numberOfSeats?: number;
  assigneAllowToFind?: boolean;
  assigneEmail?: string;
}

const GREEN = "#01EC96";
const RED = "#EA1D25";

const OPACITY = 0.3;

export const useBookingsMarkers = (
  rooms: FloorChildrenType[],
  desks: FloorChildrenType[],
  settings?: MarkerSettings
) => {
  const { markerData, cirleData, polygonalData } = useMarkerData(
    rooms,
    desks,
    [],
    settings
  );
  const markersCacheRef = useRef<MarkerType[]>([]);
  const circleCacheRef = useRef<L.Circle[]>([]);
  const polygonCacheRef = useRef<L.Polygon[]>([]);

  const { searchedAttendees } = useBookingsMapContext();
  const { getFloorEntityLeafletIcon } = floorPlanHelpers();

  const markers = useMemo<MarkerType[]>(() => {
    markersCacheRef.current.forEach((cachedMarker) => {
      cachedMarker.marker.remove();
    });

    const _markers = markerData.flat().map((floorEntity: any) => {
      return {
        id: floorEntity.id,
        marker: L.marker(floorEntity?.latlng, {
          icon: getFloorEntityLeafletIcon(floorEntity, "bookings"),
          draggable: false,
          attribution: floorEntity?.id,
          alt: "Marker",
        }),
        isBooked: floorEntity.isBooked,
        isAssigned: floorEntity.isAssigned,
        isBlocked: floorEntity.isBlocked,
        busySlot: floorEntity.busySlots,
        type: floorEntity.type,
        location: floorEntity?.location,
        name: floorEntity.name,
        tags: floorEntity.tags,
        username: floorEntity?.username,
        avatar: floorEntity?.avatar,
        attachedUserPopup: false,
        amenities: floorEntity?.amenities || [],
        numberOfSeats: floorEntity?.numberOfSeats,
        assigneAllowToFind: floorEntity?.assigneAllowToFind,
        assigneEmail: floorEntity?.assigneEmail,
        attendeeImage: "",
      };
    });

    // we add extra markers for when a user from Find people matches a
    // reserved desk
    if (searchedAttendees.length > 0 && desks.length > 0) {
      searchedAttendees.map((attendee) =>
        desks
          .filter(
            (desk) => desk.isBooked && desk.organizer?.email === attendee.email
          )
          .map((deskMarker: FloorChildrenType) => {
            return _markers.push({
              busySlot: deskMarker.busySlots,
              id: deskMarker.id,
              isBooked: deskMarker.isBooked,
              isAssigned: deskMarker.isAssigned,
              isBlocked: deskMarker.isBlocked,
              location: "",
              name: deskMarker.name,
              tags: deskMarker.tags,
              type: "Desk",
              username: deskMarker.organizer?.displayName,
              avatar: "",
              attachedUserPopup: true,
              attendeeImage: attendee?.avatar?.url || "",
              amenities: [],
              numberOfSeats: 0,
              assigneAllowToFind: deskMarker.organizer?.allowToFind,
              assigneEmail: deskMarker.organizer?.email,
              marker: L.marker(
                [
                  deskMarker?.marker?.latitude || 0,
                  deskMarker?.marker?.longitude || 0,
                ],
                {
                  icon: new L.DivIcon({
                    html: renderToStaticMarkup(
                      <UserLeafletMarker
                        key={deskMarker.id}
                        attendee={attendee}
                        showReservationInfo={false}
                      />
                    ),
                    iconSize: [0, 0],
                    iconAnchor: [0, 0],
                  }),
                  draggable: false,
                  attribution: deskMarker.id,
                  alt: "User marker",
                }
              ),
            });
          })
      );
    }

    markersCacheRef.current = _markers;

    return _markers;
  }, [rooms, desks, searchedAttendees]);

  const openMarkerOnInitialization = (id: string) => {
    let clickedMarker = markers.filter(
      ({ marker }) => marker.options.attribution === id
    );

    if (clickedMarker.length >= 1) {
      clickedMarker[0].marker.openPopup();
    }
  };

  return { markers, openMarkerOnInitialization };
};
