import React, { useState } from "react";
import cs from "classnames";
// import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";

import { DropdownList } from "../../shared/DropdownList/DropdownList";
import { Button, ThemeButtonProps } from "../../shared/Button/Button";

interface Props extends ThemeButtonProps {
  parentId?: string;
  adjustable?: boolean;
  onClick?: () => void;
  handleAddRoomButtonClick?: (id?: string) => void;
  allowShapeDraw?: boolean;
}

const options = [
  {
    icon: "shape-button-icon",
    label: "Draw room shape",
    type: "room-shape",
  },
  {
    icon: "marker-button-icon",
    label: "Place room marker",
    type: "room-marker",
  },
];

export const AddMarkerButton = (props: Props) => {
  const {
    outline,
    size,
    title,
    lineHeight,
    color,
    className,
    onClick,
    handleAddRoomButtonClick,
    allowShapeDraw,
    parentId,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  // const ref = useRef(null);

  // useOutsideClickDetection(
  //   ref,
  //   () => {
  //     if (!isOpen) {
  //       return;
  //     }
  //     setActiveRoomIdDraw && setActiveRoomIdDraw(undefined);

  //     setIsOpen(false);
  //   },
  //   isOpen
  // );

  const classes = cs(
    {
      "adjustable-button": props.adjustable,
    },
    className
  );

  return (
    <>
      <div className={classes}>
        <Button
          size={size}
          color={color}
          outline={!!outline}
          lineHeight={lineHeight}
          title={title ? title : "+"}
          onClick={() => {
            return onClick && onClick();
          }}
        />
      </div>
      {isOpen && allowShapeDraw && (
        <DropdownList
          options={options}
          largeUI
          onClick={(value) => {
            if (value === "room-marker" && onClick) {
              return onClick();
            }

            if (
              value === "room-shape" &&
              handleAddRoomButtonClick &&
              parentId?.length
            ) {
              handleAddRoomButtonClick(parentId);

              //automatically focus polygon button when tooltip is shown:
              const addPolygonButton = document.querySelector(
                ".leaflet-draw-draw-polygon"
              );
              if (addPolygonButton) {
                (addPolygonButton as HTMLElement).click();
              }

              return window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }}
        />
      )}
    </>
  );
};
